import {
    MULTI_SELECT,
} from "@/outscal-commons-frontend/FormBuilder/InputTypes";
import * as Yup from "yup";

export const formData = {
    assessment_skills: {
        inputType: MULTI_SELECT,
        optionsList: [],
        validation: Yup.array()
            .min(1, "Please select at least one option")
            .required("Required Field*"),
    },
};