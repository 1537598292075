import React, { useState, useEffect, useRef } from "react";
import styles from "./AssessmentFeedback.module.css";
import useTechStackActions from "@/store/actions/techStackAction";
import usePopupActions from "@/store/actions/popupAction";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import { FormBuilder } from "@/outscal-commons-frontend";
import { formData as initialFormData } from "./AssessmentFeedback.model";
import AssessmentFeedbackController from "./AssessmentFeedback.controller";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

const AssessmentFeedback = ({ heading, subHeading, ctaText, ctaLink }) => {
  const [formData, setFormData] = useState(initialFormData);
  const { techStackList } = useTechStackActions();
  const { user, setPreference } = useUserActions();
  const { showPopup } = usePopupActions();

  const feedbackController = useRef(
    new AssessmentFeedbackController(showPopup, setPreference)
  );

  useEffect(() => {
    if (techStackList && techStackList.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        assessment_skills: {
          ...prevFormData.assessment_skills,
          optionsList: techStackList,
        },
      }));
    }
  }, [techStackList]);

  return (
    <section data-outscal-section className={styles.section}>
      <div
        className={styles.root}
        view-analytics={[UIElements.CARD, `assessment-feedback-plug`]}
      >
        <p className={styles.heading}>{heading}</p>
        <p className={styles.subHeading}>{subHeading}</p>
        <FormBuilder
          formName={"assessments_feedback"}
          Inputs={formData}
          inputStyles={{ direction: "column" }}
          formStyles={{ gap: "12px" }}
          onSubmit={(e) => {
            feedbackController.current.onSubmit(e, user);
          }}
          submitText={ctaText}
        />
      </div>
    </section>
  );
};

export default AssessmentFeedback;
