import { toast } from "react-toastify";
import { PRO_REGISTER_POPUP } from "@/store/actions/popupAction";
import { NetworkManager } from "@/outscal-commons-frontend";

class AssessmentFeedbackController {
  constructor(showPopup, setPreference) {
    this.showPopup = showPopup;
    this.setPreference = setPreference;
  }

  async onSubmit(formData, user) {
    if (!user?.userDetails) {
      return this.showPopup(PRO_REGISTER_POPUP);
    }

    const preparedPreferenceData = {
      assessment_skills: [
        ...new Set([
          ...(formData?.assessment_skills?.map((item) => item.id) || []),
        ]),
      ].filter(Boolean),
    };

    try {
      const preferenceResp = await NetworkManager.put("/edit-preference", {
        preference: JSON.stringify(preparedPreferenceData),
      });

      if (preferenceResp.statusCode === 200) {
        this.setPreference(preferenceResp.preference);
        toast.success("Feedback submitted successfully.");
      } else {
        toast.error("Feedback submission failed");
      }
    } catch (error) {
      toast.error("An error occurred while submitting feedback");
      console.error("Error:", error);
    }
  }
}

export default AssessmentFeedbackController;
